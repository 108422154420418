import React from 'react';
import { Player } from 'video-react';
import '../../../node_modules/video-react/dist/video-react.css'; // import css
import classes from './Video.module.css';

const video = (props) => {
    return (
        <div id={classes.player} data-testid="player">
            <Player
                autoPlay
                src="https://media.w3.org/2010/05/sintel/trailer_hd.mp4"
            />
        </div>
    );
};

export default video;